interface IOptionlocaleIncludes {
  position?: number
  locales?: string | string[]
}

export const localeIncludes = (
  string: string,
  searchString: string,
  options: IOptionlocaleIncludes & Intl.CollatorOptions = {},
): boolean => {
  if (!string || !searchString) {
    return false
  }
  if (searchString.length === 0) return true

  const { position = 0, locales = 'en', ...opt } = options
  for (let i = position; i <= string.length - searchString.length; i++) {
    if (
      string.substring(i, i + searchString.length).localeCompare(searchString, locales, opt) === 0
    ) {
      return true
    }
  }

  return false
}

export function listToSentence(list: string[], separator = ', ', lastSeparator = ' et '): string {
  if (list.length === 0) {
    return ''
  }
  if (list.length === 1) {
    return list[0]
  }
  if (list.length === 2) {
    return list.join(lastSeparator)
  }
  return `${list.slice(0, -1).join(separator)}${lastSeparator}${list.slice(-1)}`
}

export function characterCount(text: string): number {
  if (!text) return 0
  const words = text.replace(/<[^>]*>/g, '').match(/\S+/g) ?? ['']
  const nbSpace = words.length - 1
  const nbChar = words.join('').length

  return nbChar + nbSpace
}
