import { ExpandableZone } from '../ExpandableZone'
import { theme } from '../../../styles'

export type IDescriptionHeader = {
  className?: string
  text: string
  backgroundColor?: string
  color?: string
}

export function DescriptionHeader({
  className,
  text,
  backgroundColor = theme.cssVars.deepBlue,
  color = theme.cssVars.white,
}: IDescriptionHeader): JSX.Element {
  if (!text) {
    return null
  }

  return (
    <>
      <div className={`DescriptionHeader ${className ? className : ''}`}>
        <ExpandableZone backgroundColor={backgroundColor} color={color} height="90px" text={text} />
      </div>

      <style jsx>
        {`
          .DescriptionHeader {
            width: 100%;
            position: relative;
            margin-top: 40px;
          }

          .DescriptionHeader :global(.ExpandableZone__Content) {
            font-weight: 300;
            color: ${color};
            text-align: center;
          }

          .DescriptionHeader :global(.ExpandableZone__Content :global(a)) {
            text-decoration: underline;
          }
        `}
      </style>
    </>
  )
}
